import React, { useState, useContext, useEffect } from "react"
import Layout from "../components/layout/layout"
import { URLS } from "../config/urls"
import axios from "axios"
import { userContext } from "../context/userContext/userContext"
import { navigate } from "gatsby"
import SEO from "../components/seo/seo"

const Payment = props => {
  const [loaded,setLoaded] = useState(false)
  const options = {
    key: 'rzp_test_0ZA9xXp3yesUef',
    amount: '100', //  = INR 1
    name: "Treato",
    description: 'mundan ke liye',
    image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
    handler: function(response) {
      // debugger
    },
    prefill: {
      name: 'Gulshan',
      contact: '8527209839',
      email: 'demo@demo.com'
    },
    notes: {
      address: 'some address'
    },
    theme: {
      color: 'blue',
      hide_topbar: false
    }
  };

  function loadScript( url, callback ) {
    let script = document.createElement( "script" )
    script.type = "text/javascript";
    if(script.readyState) {  // only required for IE <9
      script.onreadystatechange = function() {
        if ( script.readyState === "loaded" || script.readyState === "complete" ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {  //Others
      script.onload = function() {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName( "head" )[0].appendChild( script );
  }

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js",()=>{
      setLoaded(true)
    })
  }, []);

  return (
    <button disabled={!loaded} onClick={()=>{
      new window.Razorpay(options).open()
    }}> pay </button>
  )
}

export default Payment
